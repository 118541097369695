@if(!showSplashScreen) {
  <div class="bg_lines white"></div>
  <div class="root__page z-[2]" [ngClass]="{'auth-page': !logged}">
    <ion-app class="main-site">
      <ion-router-outlet></ion-router-outlet>
    </ion-app>
  </div>

  <div *ngIf="logged" class="fixed 2xl:flex hidden top-0 left-0 pointer-events-none z-[1]">
    <div class="w-[470px] max-w-full block before:block before:pb-[100%] relative">
      <img src="/assets/animations/tuy-white.gif" alt="TUY" class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[300px] max-w-full">
    </div>
  </div>
}

@if(showSplashScreen) {
  <div class="content__splashscreen">
    @if(checkSplashScreen) {
      <div class="bg_lines white"></div>
      @if(!loadingShort) {
        <div class="w-[470px] max-w-full block before:block before:pb-[100%] relative">
          <img src="/assets/animations/tuy-white.gif" alt="TUY" class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-full max-w-full">
        </div>
      } @else {
        <div class="lg:w-[300px] w-[230px] max-w-full block before:block before:pb-[100%] relative">
          <img src="/assets/img/qpos_logo-white.svg" alt="QPOS" class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-full max-w-full">
        </div>
        <ng-lottie [options]="optionsSplash" class="splash-screen-loading"></ng-lottie>
      }
    }
  </div>
}
