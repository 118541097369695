import { Injectable, OnDestroy } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';
import { Observable, fromEvent, merge, of, BehaviorSubject, Subscription } from 'rxjs';
import { mapTo } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class NetworkService implements OnDestroy {

    private online: Observable<boolean> = of(false);
    private hasConnection = new BehaviorSubject(true);

    private unsubscribe: Subscription[] = [];

    constructor(
        private network: Network,
        private platform: Platform
    ) {

        if (this.platform.is('capacitor')) {
            // on Device
            let disconnectSubscription1 = this.network.onConnect().subscribe(() => {
                console.log('network was connected :-)');
                this.hasConnection.next(true);
                return;
            });
            this.unsubscribe.push(disconnectSubscription1);

            let disconnectSubscription2 = this.network.onDisconnect().subscribe(() => {
                console.log('network was disconnected :-(');
                this.hasConnection.next(false);
                return;
            });

            this.unsubscribe.push(disconnectSubscription2);
        } else {
            // on Browser
            this.online = merge(
                of(navigator.onLine),
                fromEvent(window, 'online').pipe(mapTo(true)),
                fromEvent(window, 'offline').pipe(mapTo(false))
            );

            let disconnectSubscription3 = this.online.subscribe((isOnline) =>{
                if (isOnline) {
                    this.hasConnection.next(true);
                   console.log('network was connected :-)');
                } else {
                    console.log('network was disconnected :-(');
                    this.hasConnection.next(false);
                    console.log(isOnline);
                }
            });
            this.unsubscribe.push(disconnectSubscription3);
        }
    }

    public getNetworkType(): string {
        return this.network.type;
    }

    public getNetworkStatus(): Observable<boolean> {
        return this.hasConnection.asObservable();
    }

    ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe());
	}

}