<ion-content class="ion-padding">
    <div class="min-h-full relative flex w-full">
        <button (click)="dismiss()" type="button" class="absolute top-0 right-0 z-[2] text-white bg-transparent focus:outline-none text-[30px] p-2.5 text-center inline-flex items-center">
            <ion-icon name="close-outline"></ion-icon>
        </button>
        <div class="min-h-full w-full relative flex items-center justify-center text-center bg-[color:var(--primary-color)] overflow-hidden">
            <div class="bg_lines white"></div>
            <div class="w-auto h-auto max-w-full flex flex-col items-center">
                <div class="animate__backInDown animate__animated animate__init">
                    <svg width="129" height="128" viewBox="0 0 129 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_489_5794" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="129" height="128">
                            <rect x="0.5" width="128" height="128" fill="#D9D9D9"/>
                        </mask>
                        <g mask="url(#mask0_489_5794)">
                            <path d="M48.6333 113.2L39.7 98.267L22.7667 94.5336L24.5 77.067L13.0333 64.0003L24.5 50.9336L22.7667 33.467L39.7 29.7336L48.6333 14.8003L64.5 21.467L80.3667 14.8003L89.3 29.7336L106.233 33.467L104.5 50.9336L115.967 64.0003L104.5 77.067L106.233 94.5336L89.3 98.267L80.3667 113.2L64.5 106.534L48.6333 113.2ZM50.9 106.4L64.5 100.8L78.1 106.4L85.8333 93.6003L100.5 90.4003L99.1667 75.2003L109.033 64.0003L99.1667 52.8003L100.5 37.6003L85.8333 34.4003L78.1 21.6003L64.5 27.2003L50.9 21.6003L43.1667 34.4003L28.5 37.6003L29.8333 52.8003L19.9667 64.0003L29.8333 75.2003L28.5 90.4003L43.1667 93.6003L50.9 106.4ZM58.9 79.2003L85.3 52.8003L81.5667 48.9336L58.9 71.6003L47.4333 60.267L43.7 64.0003L58.9 79.2003Z" fill="white"/>
                        </g>
                    </svg>
                </div>
                <div class="animate__backInUp animate__animated animate__init">
                    <h4 class="text-2xl text-semibold text-white py-4 px-4">{{ message }}</h4>
                </div>
            </div>
        </div>
    </div>
</ion-content>