import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { filter } from 'rxjs/operators';
import "@capacitor-community/firebase-analytics";
import { XtrasService } from './xtras.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private router: Router,
    private xServices: XtrasService,
  ) {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      console.log('route changed: ', event.url);
      this.setScreenName(event.url);
    });
    this.initializeApp();
  }
  
  async initializeApp() {
    if(!this.xServices.isApp()) {
      await FirebaseAnalytics.initializeFirebase({
        apiKey: "AIzaSyBxhqaT5sHZ7IsINOaHYTpeC3mpe44asi4",
        authDomain: "qhantuy-e4bf2.firebaseapp.com",
        projectId: "qhantuy-e4bf2",
        storageBucket: "qhantuy-e4bf2.appspot.com",
        messagingSenderId: "416533235204",
        appId: "1:416533235204:web:c7cb0835c3e9f289c4d751",
        measurementId: "G-57ZB412ZHM"
      });
    }
    // FirebaseAnalytics.enable();
  }

  async setUserId(userId: string) {
    return await FirebaseAnalytics.setUserId({
      userId: userId,
    });
  }

  async setUserProperty(name: string, value: string) {
    return await FirebaseAnalytics.setUserProperty({
      name: name,
      value: value,
    });
  }

  async setScreenName(screenName: string, nameOverride?: string) {
    return await FirebaseAnalytics.setScreenName({screenName, nameOverride});
  }

  async logEvent(name: string, params?: object) {
    return await FirebaseAnalytics.logEvent({
      name: name,
      params: params ?? {},
    });
  }
}