import { Injectable } from '@angular/core';
import { addIcons } from 'ionicons';
import { warningOutline, wifiOutline } from 'ionicons/icons';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading = false;
  private isLoadingAlt = false;
  private isLoadingNetwork = false;
  private isLoadingShowNotLastVersionApp = false;
  constructor(
  ) {
    addIcons({ wifiOutline, warningOutline });
  }

  dismiss() {
    if(this.isLoading) {
      $('.loading_wrapper.loading_zero').remove();
      this.isLoading = false;
    }
  }

  dismissAlt() {
    if(this.isLoadingAlt) {
      $('.loading_wrapper.loading_first').remove();
      this.isLoadingAlt = false;
    }
  }

  dismissLoadingNetwork() {
    if(this.isLoadingNetwork) {
      $('.loading_wrapper.not_available_network').remove();
      this.isLoadingNetwork = false;
    }
  }

  dismissLoadingisLoadingShowNotLastVersionApp() {
    if(this.isLoadingShowNotLastVersionApp) {
      $('.loading_wrapper.not_available_latest_app').remove();
      this.isLoadingShowNotLastVersionApp = false;
    }
  }

  loadingShow(loadingType?: string, message?: string) {
    if(!this.isLoading) {
      // console.log('loading');
      let default_message = '<div class="loads-ellipsis"><div></div><div></div><div></div><div></div></div>';
      if(loadingType && loadingType == 'loading_2'){
        loadingType = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
      } else {
        loadingType = loadingType ? loadingType : default_message;
      }
      // $('body') // * generalmente es en body pero en ionic es mejor dentro de ion-app.main-site
      $('ion-app.main-site').append(`<div class="loading_wrapper loading_normal loading_zero"><div class="backdrop_loading"></div><div class="loading__content">${loadingType}<p>${message || 'Cargando...'}</p></div></div>`);
      this.isLoading = true;
      // setTimeout(() => {
      //   if(this.isLoading) {
      //     $('.loading_wrapper.loading_normal').remove();
      //     this.isLoading = false;
      //   }
      // }, 10000);
    }
  }

  loadingShowAlt(loadingType?: string, message?: string) {
    if(!this.isLoadingAlt) {
      // console.log('loading');
      let default_message = '<div class="loads-ellipsis"><div></div><div></div><div></div><div></div></div>';
      if(loadingType && loadingType == 'loading_2'){
        loadingType = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
      } else {
        loadingType = loadingType ? loadingType : default_message;
      }
      // $('body') // * generalmente es en body pero en ionic es mejor dentro de ion-app.main-site
      $('ion-app.main-site').append(`<div class="loading_wrapper loading_normal loading_first"><div class="backdrop_loading"></div><div class="loading__content">${loadingType}<p>${message || 'Cargando...'}</p></div></div>`);
      this.isLoadingAlt = true;
      // setTimeout(() => {
      //   if(this.isLoadingAlt) {
      //     $('.loading_wrapper.loading_normal').remove();
      //     this.isLoadingAlt = false;
      //   }
      // }, 10000);
    }
  }

  loadingShowNetwork(loadintType?: string) {
    if(!this.isLoadingNetwork) {
      // console.log('loading');
      let default_message = '<div class="not_available_network_icon"><ion-icon name="wifi-outline"></ion-icon></div>';
      if(loadintType && loadintType == 'loading_2'){
        loadintType = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
      } else {
        loadintType = loadintType ? loadintType : default_message;
      }
      $('body').append(`<div class="loading_wrapper not_available_network"><div class="backdrop_loading"></div><div class="loading__content">${loadintType}<h3>Sin Internet</h3><p>Por favor, compruebe la conexión y vuelva a intentarlo</p></div></div>`);
      this.isLoadingNetwork = true;
    }
  }

  loadingShowNotLastVersionApp(title?: string, text?: string) {
    if(!this.isLoadingShowNotLastVersionApp) {
      // console.log('loading');
      let loadintType = '<div class="not_available_network_icon"><ion-icon name="warning-outline"></ion-icon></div>';
      $('body').append(`<div class="loading_wrapper not_available_latest_app"><div class="backdrop_loading"></div><div class="loading__content">${loadintType}<h3>${title ?? 'Aplicación desactualizada'}</h3><p>${text ??'Por favor, actualice a la última versión de la aplicación y vuelve a intentarlo'}</p></div></div>`);
      this.isLoadingShowNotLastVersionApp = true;
    }
  }
}
