
 import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Platform, ModalController } from '@ionic/angular';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoadingClientProvider } from './loading-client';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { FileItem } from '../interfaces/interfaces';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
// import { ModalMessagesComponent } from '../modals/messages/messages.component';

import { Plugins } from '@capacitor/core';
const { DemoPlugin, OthersPlugin } = Plugins;

import { Http, HttpOptions, HttpResponse } from '@capacitor-community/http';
import { ModalMessagesComponent } from '../modals/messages/messages.component';

export interface Data {
    title: string;
}

@Injectable({
    providedIn: 'root',
})
export class XtrasService {

    private emitChangeSource: BehaviorSubject<Data> = new BehaviorSubject<Data>({title: 'QH Organizaciones'});

    get sharingObservable() {
        return this.emitChangeSource.asObservable();
    }

    set sharingObservableData(data: Data) {
        this.emitChangeSource.next({...data});
    }

	private emitChangeFile: Subject<FileItem> = new Subject<FileItem>();

    get fileObservable() {
        return this.emitChangeFile.asObservable();
    }

    set fileObservableData(data: FileItem) {
        this.emitChangeFile.next(data);
    }

    constructor(
        private load: LoadingClientProvider,
        private platform: Platform,
        private fileOpener: FileOpener,
        private modalCtrl: ModalController,
    ) {}

    lengthItems(items: any) {
        if(Array.isArray(items)) {
            if(items.length >= 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    formatDate(date?: any) {
        date = date ? date : new Date();
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

        const dateFormat = new Date(date);
        return `${monthNames[dateFormat.getMonth()]} ${dateFormat.getDate()}, ${dateFormat.getFullYear()}`;
    }

    formatDateNormal(date: any){
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    filterDataId(id: any, data: any[]) {
        const result = data.filter(itemInArray => itemInArray.id === id);
        // console.log(result);
        return result[0];
    }

    filterDataIds(id: any, data: any[]) {
        let findedData = data.find(i => i.id === id);
        if (typeof findedData === 'undefined') {
            return null;
        }
        return findedData;
    }

    // * object to queryParams
    serialize = function(obj: any) {
        var str = [];
        for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
        return str.join("&");
    }

    getFormattedTime() {
		var today = new Date();
		var y = today.getFullYear();
		// JavaScript months are 0-based.
		var m = today.getMonth() + 1;
		var d = today.getDate();
		var h = today.getHours();
		var mi = today.getMinutes();
		var s = today.getSeconds();
		return y + '_' + m + '_' + d + '_' + h + '_' + mi + '_' + s;
	}

    async saveFile(name: string, file: string, extension: string) {
        if(this.isAppAndroid()) {
            let data: any = await this.checkVersionAndroid();
            // 30 versión de android 11
            if(data&&data['sdkVersion']) {
                if(data['sdkVersion'] < 30) {
                    if(file.startsWith('http') || file.startsWith('https')) {
                        let resp = await this.downloadFileCapacitor(file, name, extension);
                        return resp;
                    } else{
                        let resp = await this.saveAsFileBase64(name, file, extension);
                        console.log(resp);
                        return {
                            reintent: false,
                            data: resp,
                        };
                    }
                } else {
                    let pluginResp = await OthersPlugin['initDownload']({ url: file, name: name, extension: extension });
                    console.log(pluginResp);
                    if(pluginResp && pluginResp.status) {
                        if(pluginResp.status == 'true') {
                            return{
                                reintent: false,
                                data: pluginResp.data
                            };
                        } else if(pluginResp.type == 'reintent') {
                            return {
                                reintent: true,
                                data: null
                            };
                        } else {
                            return null;
                        }
                    }
                }
            } else {
                let resp = await this.saveAsFileBase64(name, file, extension);
                console.log(resp);
                return {
                    reintent: false,
                    data: resp,
                };
            }
            return null;
        } else {
            if(file.startsWith('http') || file.startsWith('https')) {
                let resp = await this.downloadFileCapacitor(file, name, extension);
                return resp;
            } else {
                let resp = await this.saveAsFileBase64(name, file, extension);
                console.log(resp);
                return {
                    reintent: false,
                    data: resp,
                };
            }
        }
    }

    async downloadFileCapacitor(URL: any, name: string, extension: string, save: boolean = true) {
        const options:HttpOptions = {
            url: URL,
            responseType: 'blob',
        };
        
        
        const response: HttpResponse = await Http.get(options);
        console.log(response);
        if(response&&response.data) {
            if(response.data instanceof Blob) {
                let base64Data = (await this.convertBlobToBase64(response.data)) as string;
                if(save) {
                    let resp = await this.saveAsFileBase64(name, base64Data, extension);
                    console.log(resp);
                    return {
                        reintent: false,
                        data: resp,
                    };
                } else {
                    return base64Data;
                }
            } else if(typeof response.data === 'string' && this.isBase64(response.data)) {
                if(save) {
                    let resp = await this.saveAsFileBase64(name, response.data, extension);
                    console.log(resp);
                    return {
                        reintent: false,
                        data: resp,
                    };
                } else {
                    return response.data;
                }
            } else {
                console.error('response.data no es un Blob ni base64');
                // alert('El archivo a descargar no tiene un formator válido');
                return null;
            }
        } else{
            return null
        }
    }

    isBase64 = (str: string) => {
        try {
            atob(str);
            return true;
        } catch {
            return false;
        }
    }

    convertBlobToBase64 = (blob: Blob) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        }
    )

    saveAsFileBase64(filename: string, fileBase64: string, extension: string) {
        return new Promise((resolve) => {
            Filesystem.writeFile({
                path: `${filename}.${extension}`,
                data: fileBase64,
                directory: Directory.Cache
            }).then((val) => {
                console.log(val);
                Filesystem.getUri({
                    path: `${filename}.${extension}`,
                    directory: Directory.Cache
                }).then((uri) => {
                    console.log(uri);
                    this.load.presentToast(('Archivo descargado con éxito'));
                    resolve(uri.uri);
                }).catch(err => {
                    console.log(err);
                    this.load.presentToast('Hubo un error al descargar el archivo, ' + err, 5000);
                    resolve(null)
                });
            }).catch(error => {
                console.log(error);
                this.load.presentToast('Hubo un error al descargar el archivo, '+ error, 5000);
                resolve(null)
            });
        });
    }

    async openFile(uri: any, type: string) {
        if(this.isAppAndroid()) {
            let data: any = await this.checkVersionAndroid();
            if(data) {
                // 30 versión de android 11
                if(data['sdkVersion']&&data['sdkVersion'] < 30) {
                    console.log(uri, type);
                    this.openFileCapacitor(uri, type);
                } else {
                    let pluginResp = await OthersPlugin['openFile']({ path: uri, type: type });
                    console.log(pluginResp);
                    if(pluginResp && pluginResp.success) {
                        // No hacer nada
                    } else {
                        this.load.presentToast(pluginResp?.message ?? 'No se pudo abrir el archivo.');
                    }
                }
            } else {
                let pluginResp = await OthersPlugin['openFile']({ path: uri, type: type });
                console.log(pluginResp);
                if(pluginResp && pluginResp.success) {
                    // No hacer nada
                } else {
                    this.load.presentToast(pluginResp?.message ?? 'No se pudo abrir el archivo.');
                }
            }
        } else {
            this.openFileCapacitor(uri, type);
        }
    }

    private openFileCapacitor(uri: any, mimetype: any) {
        this.fileOpener.open(uri, mimetype)
            .then(() => console.log('File is opened'))
            .catch(e => console.log('Error opening file', e));
    }


    // private async openFile(uri) {
    //     const contents = await Filesystem.readFile({
    //         path: uri,
    //         // directory: Directory.Documents,
    //     });

    //     console.log('data', contents);
    // }

    readFilePath = async (uri: any) => {
        return from(Filesystem.requestPermissions()).pipe(
            switchMap(_ => (
                console.log(_),
                from(Filesystem.readFile({path: uri}))
            )), // My app crashes here !!!
        );

    }

    extractDataTypeFileBase64(base64: any) {
        if(base64) {
            let data = { type: 'application', ext: 'pdf' };
            let dataBase64 = base64.split(';')[0].split('data:')[1];
            console.log(data);
            let type = dataBase64.split('/')[0];
            if(type) {
                console.log(type);
                data.type = type;
            }
            let ext = dataBase64.split('/')[1];
            if(ext) {
                console.log(ext);
                data.ext = ext;
            }

            console.log(data);

            return data;
        }
        return null;
    }

    isApp(): boolean {
        return (this.platform.is('capacitor') &&(this.platform.is('android')||this.platform.is('ios')));
    }

    isAppAndroid(): boolean {
        return (this.platform.is('capacitor') &&this.platform.is('android'));
    }

    isAppIos(): boolean {
        return (this.platform.is('capacitor') &&this.platform.is('ios'));
    }

    numericOnly(event: any): boolean {
        let pattern = /^([0-9])$/;
        let result = pattern.test(event.key);
        return result;
    }

    textOnly(event: any): boolean {
        let pattern = /^([a-zA-Z\s]+)$/;
        let result = pattern.test(event.key);
        return result;
    }

    textOnlyAccent(event: any): boolean {
        let pattern = /^([a-zA-Z\sáéíóúÁÉÍÓÚüÜ]+)$/;
        let result = pattern.test(event.key);
        return result;
    }

    minDateOfYear(valid: number): Date {
        var today = new Date();
        return new Date(today.getFullYear() - valid, today.getMonth(), today.getDate());

    }

    minDate(valid?: Date): Date {
        var today = Date();
        return new Date(valid || today);
    }

    dataMessage(result: any, success?:boolean, hasMessage:boolean = true, showDesc:boolean = true) {
        if(success) {
            if(result['message']) {
                if(result['messages']&&this.lengthItems(result['messages'])) {
                    this.messagesAlert(result['message'], result['messages'], true, showDesc);
                } else {
                    if(hasMessage) {
                        this.load.presentToast(result['message'], 4000);
                    }
                }
            } else {
                this.load.presentToast('Información procesada correctamente.', 4000);
            }
        } else {
            if(result['message']) {
                if(result['errors']&&this.lengthItems(result['errors'])) {
                    this.messagesAlert(result['message'], result['errors'], true, showDesc);
                } else {
                    if(hasMessage) {
                        this.load.presentToast(result['message'], 4000);
                    }
                }
            } else {
                this.load.presentToast('Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
            }
        }
    }

    base64FileHeaderMapper(fileBase64: string) {
        let fileHeader = new Map();

        //get the first 3 char of base64
        fileHeader.set("/9j", "JPG")
        fileHeader.set("iVB", "PNG")
        fileHeader.set("Qk0", "BMP")
        fileHeader.set("SUk", "TIFF")
        fileHeader.set("JVB", "PDF")
        fileHeader.set("UEs", "OFD")

        let res = ""

        fileHeader.forEach((v, k) => {
            if (k == fileBase64.substr(0, 3)) {
                res = v;
            }
        });

        //if file is not supported
        if (res == "") {
            res = "unknown file";
        }

        //return map value
        return res;
    }

    async messagesAlert(title:string, response: any, close: boolean, showDesc:boolean = true) {
        let messages = response;
        console.log(messages);
        const modal = await this.modalCtrl.create({
            mode: 'ios',
            component: ModalMessagesComponent,
            cssClass: 'middle__modal',
            componentProps: { close, title, messages, showDesc },
            canDismiss: close,
        });
        await modal.present();
    }

    async actionCustomToastJAVA(action: 'show'|'cancel', message?: string, timeout?: number) {
        let toast = await DemoPlugin['runNativeCode']({ method: 'customToast', message, action, timeout: timeout ? timeout.toString() : null });
        console.log(toast);
    }

    async checkVersionAndroid() {
        let data = await OthersPlugin['checkAndroidVersion']();
        return data?.data ?? null;
        // console.log(data);
    }

    openLink(url: string, target?: string) {
        window.open(url, target);
    }

    async vibrate(type: 'light'|'medium'|'high') {
        if(type == 'light') {
            await Haptics.impact({ style: ImpactStyle.Light });
        } else if(type == 'medium') {
            await Haptics.impact({ style: ImpactStyle.Medium });
        } else {
            await Haptics.impact({ style: ImpactStyle.Heavy });
        }
    }
}
