
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface StatusData {
    status: 'notInitialize' | 'initialize' | 'loading' | 'success' | 'error' | 'incompatible';
}

@Injectable({
    providedIn: 'root',
})
export class StatusNFCService {
    private emitChangeStatusNFC: BehaviorSubject<StatusData> = new BehaviorSubject<StatusData>({status: 'notInitialize'});

    get getStatusNFC() {
        return this.emitChangeStatusNFC.asObservable();
    }

    set setStatusNFCData(data: StatusData) {
        this.emitChangeStatusNFC.next(data);
    }
}