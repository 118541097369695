import { Component, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { XtrasService } from '../../services/xtras.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'modal-messages',
  templateUrl: 'messages.component.html',
  standalone: true,
  imports: [
    CommonModule,
  ]
})
export class ModalMessagesComponent implements OnDestroy {

  pin:string = '';
  form: any;

  message: any = null;

  messages: any;
  title: string | null = null;

  close: boolean = true;
  showDesc: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    public xServices: XtrasService,
  ) {}

  ngOnInit() {
    console.log(this.messages);
    if(this.xServices.lengthItems(this.messages)) {
      if(this.textFormat(this.messages)) {
        this.message = this.textFormat(this.messages);
      }
    } else {
      this.message = this.messages;
    }
  }

  dismiss() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  ngOnDestroy(): void {
  }

  private textFormat(messages: string[]) {
    // let data = [];
    let data = '';
    for(let item of messages) {
      // data.push(`<p>${item}</p>`);
      data = data.concat(`<p>${item}</p>`);
    }
    // console.log(data);
    return data;
  }
}
