import { Injectable, NgZone } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingClientProvider {

  constructor(
    public platform: Platform, 
    private toastCtrl: ToastController,
    private ngZone: NgZone,
  ) {}

  async presentToast(text: string, timeout?: number) {
    try {
      this.ngZone.run(async () => {
        let toast = await this.toastCtrl.create({
          message: text,
          duration: timeout ?? 3000,
          position: 'bottom',
        });
        
        await toast.present();
      });
    } catch (error) {
      console.error('Error creating toast:', error);
    }
  }

}
