import { Routes } from '@angular/router';

export const mainRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./app.routes'),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth.routes'),
  },
];
