export const environment = {
  methods: {
    TAP_TO_PHONE: 'tap-to-phone',
    QR_CONTINGENCY: 'qr-contingency',
    QR_SIMPLE: 'qr',
    CYBERSOURCE_CHECKOUT: 'cybersource-checkout',
    CASH: 'cash',
    DEPOSIT_IN_ACCOUNT: 'deposit-in-account',
    PHYSICAL_CARD: 'physical-card',
    PHONE: 'phone',
    BCP_CREDIT_CARD: 'bcp-credit-card',
    BCP_CARD: 'bcp-card',
    BCP_DEBIT_CARD: 'bcp-debit-card',
    YAPE: 'yape',
    CANASTA: 'canasta',
    QH_QHANTUY: 'qh_qhantuy',
  },
  TAP_TO_PHONE: {
    // Transaction
    // Transaction Status
    IS_TRANSACTION_APPROVED: 'is_transaction_approved',
    RESPONSE_CODE_DESCRIPTION: 'response_code_description',

    // Terminal
    IS_ACTIVE_TERMINAL: 'is_active_terminal',
    IS_NOT_ACTIVE_TERMINAL: 'is_not_active_terminal',
    TERMINAL_DATA: 'terminal_data',

    // Transaction errors
    LECTURA: 'Lectura',
    TIMEOUT: 'timeout',
    ON_COULD_NOT_CONNECT_SERVICE: 'on_could_not_connect_service',
    GPS_IS_NEEDED: 'gps_is_needed',
    // TRANSACTION FAILED REASONS
    PIN_CANCELED: 'pin_cancel',
    CUOTAS: 'Cuotas',
    EXTRAFINANCIAMIENTO: 'Extrafinanciamiento',
    COMERCIO: 'Comercio',
    TARJETA: 'Tarjeta',
    MONEDA: 'Moneda',
    COMPROBANTE: 'Comprobante',
    TRANSACCION: 'Transacción',
    IMPUESTO: 'Impuesto',
    OFFLINE: 'Offline',
    APLICACION: 'Aplicación',
    TRANSACTION_CANCELLED: 'transaction_cancelled',
  },
  NFC: {
    NFC_NOT_FOUND: 'nfc_not_found',
    ACTIVE: 'on',
    INACTIVE: 'off',
  },
};
