import {Injectable} from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { NavController, Platform, ModalController } from "@ionic/angular";
import { SettingsProvider } from './settings';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { ModalSuccessComponent } from '../modals/success/success.component';

@Injectable({
    providedIn: 'root'
})
export class MyOneSignalService {


    private initialized: boolean = false;

    private WebPushSDK: any = {
        loaded: false,
        src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
    };

    constructor (
        private platform: Platform,
        private navCtrl: NavController,
        private authService: AuthService,
        private modalCtrl: ModalController,
    ) {
    }

    async init() {

        if (!this.platform.is('capacitor')) {
			// **ONE SIGNAL WEB NO GENERA EL PLAYER_ID ASÍ QUE NO SE PUEDE ENVIAR NOTIFICACIONES A USUARIOS ESPECIFICOS**
            return;
        }

        return this._initCordova();
    }

    private _initCordova() {
        return new Promise((resolve, reject) => {
            if (this.initialized === true) {
                return resolve(this);
            }

            this.platform.ready().then(() => {
                OneSignal.initialize(SettingsProvider.ONESIGNAL_APP_ID);

                let $this = this;

                OneSignal.Notifications.addEventListener('click', async (e) => {
                    let clickData = await e.notification;
                    console.log("Notification Clicked : " + clickData);
                    $this.navCtrl.navigateRoot('/notifications');
                });
                
                OneSignal.Notifications.addEventListener('foregroundWillDisplay', async (e) => {
                    let data = await e.getNotification();
                    console.log("Notification foregroundWillDisplayBody: " + data.body);
                    console.log("Notification foregroundWillDisplayTitle: " + data.title);
                    console.log("Notification foregroundWillDisplay: " + JSON.stringify(data));
                    if(data.additionalData) {
                        let subdata: any = data.additionalData;
                        if(subdata.title) {
                            switch (subdata.title) {
                                case 'success-payment':
                                    if(subdata.voucher_data) {
                                        $this.navCtrl.navigateRoot('/payment-receipt', { queryParams: {dataTransaction: subdata.voucher_data} });
                                    } else {
                                        $this.successMakePayment(data.body, '/notifications');
                                    }
                                    break;
                                    
                                case 'success-cart':
                                    break;

                                case 'success-payment-physical':
                                    break;
                            
                                default:
                                    $this.navCtrl.navigateForward('/notifications');
                                    break;
                            }
                        }
                    }
                });
                
                OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
                    console.log("Notification permission granted " + success);
                    if(success) {
                        OneSignal.User.pushSubscription.getIdAsync().then(async data => {
                            if (data) {
                                console.log(`getIdAsync: ${data}`);
                                await $this.authService.setPushTokenNotification(data, false);
                            }
                        }).catch(error => {
                            console.log(`getIdAsyncError: ${error}`);
                        });
                    }
                });

                OneSignal.InAppMessages.addEventListener('willDisplay', async (e) => {
                    console.log(e.message);
                });

                this.initialized = true;

                return resolve(this);
            });
        });
    }

    loadWebPushSDK() {
        return new Promise((resolve, reject) => {
            if (this.WebPushSDK.loaded) {
                resolve(true);
            } else {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.WebPushSDK.src;

                script.onload = () => {
                    this.WebPushSDK.loaded = true;

                    resolve(true);
                };

                script.onerror = () => {
                    reject(false);
                };

                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

    obtainUserId(): Observable<string|null> {
        return new Observable(observer => {
            const getIdAsync = () => {
                OneSignal.User.pushSubscription.getIdAsync().then(data => {
                    if (!data) {
                        getIdAsync();
                    } else {
                        console.log(`getIdAsync: ${data}`);
                        observer.next(data);
                        observer.complete();
                    }
                }).catch(error => {
                    console.log(`getIdAsyncError: ${error}`);
                    observer.next(null);
                    observer.complete();
                });
            };
            getIdAsync();
        });
    }

    private async successMakePayment(message: string, urlClose?: string) {
        const modal = await this.modalCtrl.create({
            mode: 'ios',
            component: ModalSuccessComponent,
            cssClass: 'big__modal success__modal',
            componentProps: { message: message }
        });
        if(urlClose) {
          modal.onWillDismiss().then(data => {
            // console.log(data);
            setTimeout(() => {
                this.navCtrl.navigateForward(urlClose);
            }, 500);
          });
        }
        await modal.present();
    }
}
