<div class="w-full">
  <div class="p-4">
    <h3 class="text-[20px] text-center block font-medium text-[color:var(--primary-color)]">{{ title || '¡Error!' }}</h3>
    <hr>
    <ng-container *ngIf="showDesc">
      <span class="py-4 w-full block text-left" [innerHtml]="message"></span>
      <hr>
    </ng-container>
    <button (click)="dismiss()" class="btn-default w-full mt-4">Cerrar</button>
  </div>
</div>
