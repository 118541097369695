import { ModalController } from '@ionic/angular';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { mainRoutes } from './app/main.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import player from 'lottie-web';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './app/services/interceptor.service';
import { AuthService } from './app/services/auth.service';
import { LottieModule } from 'ngx-lottie';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LoadingClientProvider } from './app/services/loading-client';
import { HttpClientProvider } from './app/services/http-client';
import { MyOneSignalService } from './app/services/onesignal.service';
import { XtrasService } from './app/services/xtras.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StorageService } from './app/services/storage.service';
import { finalize } from 'rxjs';

function appInitializer(authService: AuthService, storageService: StorageService) {
  return () => {
    return new Promise<void>((resolve) => {
      storageService.init().finally(() => {
        authService.getUserByToken().pipe(
          finalize(() => resolve())
        ).subscribe();
      });
    });
  };
}

export function playerFactory() {
  return player
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-GB' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, StorageService],
    },
    Network,
    FileOpener,
    SocialSharing,
    ModalController,
    importProvidersFrom(IonicStorageModule.forRoot()),
    // importProvidersFrom(LoadingClientProvider),
    LoadingClientProvider,
    importProvidersFrom(HttpClientProvider),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(AuthService),
    importProvidersFrom(XtrasService),
    importProvidersFrom(MyOneSignalService),
    importProvidersFrom(LottieModule.forRoot({player: playerFactory})),
    provideAnimationsAsync(),
    provideIonicAngular(),
    provideRouter(mainRoutes),
  ],
});
