
import { Injectable, NgZone } from '@angular/core';

import { Plugins } from '@capacitor/core';
const { DemoPlugin, OthersPlugin, IminPlugin } = Plugins;

@Injectable({
    providedIn: 'root',
})
export class PluginService {

    constructor(
        private ngZone: NgZone,
    ) {}

    async pluginConnect(plugin: 'demo' | 'others' | 'imin', func: string, params: object = {}) {
        return await this.ngZone.run(async () => {
            let res: any = null;
            switch (plugin) {
                case 'demo':
                    res = await DemoPlugin[func](params);
                    return res ?? null;
    
                case 'others':
                    res = await OthersPlugin[func](params);
                    return res ?? null;
    
                case 'imin':
                    res = await IminPlugin[func](params);
                    return res ?? null;
            
                default:
                    console.warn('El método ingresado no existe.');
                    return null;
            }
        });
    }
}
