import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private inject: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authservice = this.inject.get(AuthService);

    return from(authservice.getToken()).pipe(
      switchMap(token => {
        let bearerToken = request.clone({});
        if(token) {
          bearerToken = request.clone({
            setHeaders: {
              Authorization: 'Bearer ' + token
            }
          });
        }
        

        return next.handle(bearerToken).pipe(
          catchError(errorData => {
            console.log(errorData);
            if (errorData.status === 401) {
              return from(authservice.logout('expired')).pipe(
                switchMap(() => throwError(errorData))
              );
            }
            return throwError(errorData);
          })
        );
      })
    );
  }
}