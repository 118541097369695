import { XtrasService } from './xtras.service';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { Injectable } from '@angular/core';

export interface StatusKeepAwakeData {
    status: 'yes' | 'no';
}

@Injectable({
    providedIn: 'root',
})
export class KeepAwakeService {

    constructor(
        private xServices: XtrasService,
    ) {}

    async init() {
        if(this.xServices.isApp()) {
            const result = await KeepAwake.isSupported();
            console.log(result);
            if(result.isSupported) {
                localStorage.setItem('KeepAwakeIsSupported', 'yes');
                
                let keepAwakeStatus = localStorage.getItem('KeepAwake') ?? null;
                if(keepAwakeStatus && keepAwakeStatus != '') {
                    this.setKeepAwake((keepAwakeStatus === 'yes') ? 'yes' : 'no');
                } else {
                    localStorage.setItem('KeepAwake', 'yes');
                    this.setKeepAwake('yes');
                }
            } else {
                localStorage.setItem('KeepAwakeIsSupported', 'no');
            }
        } else {
            localStorage.setItem('KeepAwakeIsSupported', 'no');
        }
    }

    async setKeepAwake(status: StatusKeepAwakeData['status']) {
        localStorage.setItem('KeepAwake', status);
        if(status == 'yes') {
            await KeepAwake.keepAwake();
        } else {
            await KeepAwake.allowSleep();
        }
        let data = await KeepAwake.isKeptAwake();
        console.log(data);
    }

    getKeepAwake(): StatusKeepAwakeData['status'] {
        let KeepAwakeInit = localStorage.getItem('KeepAwake');
        return (KeepAwakeInit && KeepAwakeInit === 'yes') ? 'yes' : 'no';
    }

    keepAwakeIsSupported(): StatusKeepAwakeData['status'] {
        let KeepAwakeIsSupportedInit = localStorage.getItem('KeepAwakeIsSupported');
        return (KeepAwakeIsSupportedInit && KeepAwakeIsSupportedInit === 'yes') ? 'yes' : 'no';
    }
}